import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { Form,Input, Checkbox, Button, notification, Alert } from 'antd';
import { requestSubmit } from "../services/Unsubscribe";

const Unsubscribe = ({ langData, props, responseData, redirectErrorUrl,paramsUrl }) => {

    
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState(langData.notification_error_title)
    const [alertType, setAlertType] = useState('error')
    const [errorMessage, setErrorMessage] = useState('')
    
    
    const [form] = Form.useForm();
    const validateMessages = {
        required: "${name} is required!",
        types: {
          email: "${name} is not validate email!",
        },
    };
    const [activeItemsCount, setActiveItemsCount] = useState(0);
    const [formData, setformData] = useState('')
    
    const [confirmLoading, setConfirmLoading] = useState(false)

    useEffect(() => {
        validateUrl();
    }, [responseData]);

    const handleSubmit = async (e) => {

        try {

            setConfirmLoading(true)
            setformData(e)
            const request = await requestSubmit(e)

            if (request.status_code == 200211) {
                // succcess
                setConfirmLoading(false)
                setShowAlert(true)
                setAlertType('success')
                setAlertTitle(langData.notification_success_title)

            } else {
                setShowAlert(true)

            }

        } catch (error) {

            setConfirmLoading(false)
            setShowAlert(true)

        }


    }

    const validateUrl = () => {
        
        if (!!responseData.status_code) {
    
            if (responseData.status_code == 200) {
                setActiveItemsCount(1)
            } else {

                setShowAlert(true)
                setErrorMessage(responseData.message)
            }
        }
    }

    const onChanges = e => {
        if(e.target.checked === true) {
                setActiveItemsCount( activeItemsCount + 1);      
        }
        else {
                setActiveItemsCount(activeItemsCount - 1);
        }
    }

    return (   
        <>
            {showAlert == true ? (
                <>
                    <div className="reg container container--500">
                        <h1 className="text--center">{alertTitle}</h1>

                        {alertType == 'success' ? (
                            <>
                                <p className="p--sm text--center mart--xxl">
                                    <b>{formData.email}</b>{langData.label_success_email}
                                </p>
                                <ul className="ul--sm ul--center">
                                    {formData.subscribe === true ? (<li>{langData.bullet_ayana_rewards_promotion}</li>) : ''}
                                    {formData.monthly_notification === true ? (<li>{langData.bullet_ayana_rewards_monthly}</li>) : ''}
                                </ul>
                                <p className="p--sm text--center mart--xxl">
                                    {langData.label_success_resubscribe}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="p--sm text--center mart--xxl marb--xxl">
                                        {errorMessage}
                                </p>
                            </>
                        )}    

                        <p className="p--sm text--center mart--xxl marb--xxl">
                            <Button type="primary" size="middle" block={true} onClick={() => navigate(redirectErrorUrl)} >
                                {langData.label_btn_redirect}
                            </Button>
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div className="reg container container--600">
                        <h1 className="text--center">{langData.title}</h1>
                        <hr />
            
                        <div className="action_status" style={{ textAlign: "center" }}></div>
                        <br />
                        <Form
                        form={form}
                        name="unsub_form"
                        size="middle"
                        initialValues={paramsUrl}
                        validateMessages={validateMessages}
                        onFinish={handleSubmit}
                        scrollToFirstError>
            
                        <div className="form__row">
                            <div className="col col--100">
                            <label className="label" htmlFor="email">
                                {langData.label_email}
                            </label>
                            <Form.Item name="email" className="" rules={[{ required: true }]}>
                                <Input disabled={true} />
                            </Form.Item>
                            </div>
                        </div>
                        <div className="form__row flex">
                            {langData.label_unsub_choice}
                        </div>
                        <div className="form__row flex">
                            <div className="form__col form__col--inline">
                            <Form.Item name="subscribe" valuePropName="checked">
                                <Checkbox  onChange ={onChanges}>{langData.label_unsub_marketing}</Checkbox>
                            </Form.Item>
                            </div>
                        </div>
            
                        <div className="form__row flex">
                            <div className="form__col form__col--inline">
                            <Form.Item name="monthly_notification" valuePropName="checked">
                                <Checkbox  onChange={onChanges}>{langData.label_unsub_monthly}</Checkbox>
                            </Form.Item>
                            </div>
                        </div>

                        <Form.Item name="key" className="" rules={[{ required: true }]} style={{ display: 'none' }}>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item name="campaign_id" className="" rules={[{ required: true }]} style={{ display: 'none' }}>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item name="timestamp" className="" rules={[{ required: true }]} style={{ display: 'none' }}>
                            <Input disabled={true} />
                        </Form.Item>
            
                        <div className="form__row text--center">
                            <Button
                            className="button button--cta button"
                            type="primary"
                            htmlType="submit"
                            loading={confirmLoading}
                            size="large"
                            block={true}
                            disabled={activeItemsCount === 0}>
                            {langData.label_btn_submit}
                            </Button>
                        </div>
                        </Form>
                    </div>
                </>
            )}
        </>
    );
}

export default Unsubscribe;