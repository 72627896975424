
import React, { useEffect, useContext } from "react";
import axios from 'axios'
import { fetchMemberAction } from "state/actions/memberActions";
import { UNSUBSCRIBE_CHECK_KEY } from "state/constants/actions";
import Async from "components/Async/Async";
import { useDispatch, useSelector } from "react-redux";
import { store, requestCheckKey } from "../apis/unsubscribe";
import Unsubscribe from "../components/Unsubscribe";

export const requestSubmit = (params) => {
    
    try {
        return store(params)

    } catch (error) {
        console.log(error)
    }


}

const CheckUnsubscribeKey = ({ langData, props, redirectErrorUrl }) => {

    const { member: checkUnsubscribeKey, uiState, error } = useSelector(
        (state) => state.checkUnsubscribeKey
    );
    const dispatch = useDispatch();
    useEffect(() => {
        fetchMemberAction(UNSUBSCRIBE_CHECK_KEY, dispatch, requestCheckKey, props.location.search);
    }, []);

    let paramsUrl = {}

    if (props.location.search !== '') {

        const requestUrl = props.location.search.substring(props.location.search.indexOf('?') + 1)
        const arrayParamsUrl = JSON.parse('{"' + requestUrl.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        })
        if(arrayParamsUrl.campaign_id.charAt(0) === 'M' && arrayParamsUrl.campaign_id.length == 7){
            arrayParamsUrl.monthly_notification = true
            arrayParamsUrl.subscribe = false
        }else{
            arrayParamsUrl.monthly_notification = false
            arrayParamsUrl.subscribe = true
        }
        paramsUrl = Object.assign(arrayParamsUrl);
    }

    return (
        <Async
            uiState={uiState}
            error={error}
            onSuccess={() => (
                <>
                    <Unsubscribe
                        responseData={checkUnsubscribeKey}
                        langData={langData}
                        props={props}
                        redirectErrorUrl={redirectErrorUrl}
                        paramsUrl={paramsUrl}
                    />
                </>
            )}></Async>
    );
}

export default CheckUnsubscribeKey;