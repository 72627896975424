import request from ".";
import { stringify } from "qs";
import { getAuthToken } from "../services/Auth";
import { headerConfig } from "../services/Header";

export const requestCheckKey = async (requestParams) => {

    const response = await request.get(`/api/email-notification/check-key${requestParams}`, headerConfig());
    return response;
};

export const store = async (requestParams) => {

    const response = await request.post(`/api/email-notification/unsubscribe`, requestParams, headerConfig());
    return response.data;
};